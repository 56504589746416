import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Vitality Vault'a Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Özünüze Enerji Verin"} />
			<meta property={"og:title"} content={"Ana Sayfa | Vitality Vault'a Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Özünüze Enerji Verin"} />
			<meta property={"og:image"} content={"https://mobizaplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobizaplay.com/img/2964514.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobizaplay.com/img/2964514.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobizaplay.com/img/2964514.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Potansiyelinizi Açığa Çıkarın
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Fitness yolculuğunuzun bir güç, dayanıklılık ve canlanma hikayesine dönüştüğü Vitality Vault'a hoş geldiniz. Spor salonumuz egzersiz yapabileceğiniz bir yerden çok daha fazlasıdır; her gün adım adım kendinizin en iyi versiyonunu ortaya çıkarabileceğiniz bir sığınaktır. En üst düzey ekipmanlar, uzman rehberliği ve destekleyici bir toplulukla, her terin buna değmesini sağlamak için buradayız.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 40px 12px 40px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="--darkL1"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							hover-background="rgba(63, 36, 216, 0)"
						>
							Bize Ulaşın
						</Button>
					</Box>
				</Box>
				<Image
					src="https://mobizaplay.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Vitality Vault'ta herkes için fitness'a inanıyoruz - hedeflerin sadece karşılanmakla kalmayıp aşıldığı bir yer. İster yeni başlayan ister deneyimli bir sporcu olun, çok çeşitli fitness programlarımız ve son teknoloji tesislerimiz bireysel ihtiyaçlarınızı karşılayacak şekilde tasarlanmıştır. Burada söz konusu olan sadece fiziksel dönüşüm değil, aynı zamanda sürekli gelişim ve esenliğe yönelik bir zihniyetin teşvik edilmesidir.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://mobizaplay.com/img/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://mobizaplay.com/img/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Neden Bizi Seçmelisiniz?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Kişiselleştirilmiş Antrenman Planları: Her üye, hedeflerine göre uyarlanmış bir fitness planı alır ve kendileri kadar benzersiz bir yolculuk sağlar.
					<br /><br />
					Son Teknoloji Ekipmanlar: Kardiyo makinelerinden serbest ağırlıklara kadar, sınırlarınızı zorlamak için ihtiyacınız olan her şeye sahibiz.
					<br /><br />
					Topluluk Desteği: Birbirini motive eden ve destekleyen bir topluluğa katılın, antrenmanları dört gözle bekleyin.
					<br /><br />
					Esnek Çalışma Saatleri: 7/24 açığız, programınıza uyuyoruz, tersi değil.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					İletişime Geçin
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Bizimle fitness yolculuğunuza çıkmaya hazır mısınız? Bizi ziyaret edin, arayın veya bir e-posta gönderin. Vitality Vault ailesine yeni üyeler kazandırmak için kapılarımız her zaman açık.
				</Text>
				<Button
					margin="40px 15px 0px 0px"
					padding="12px 40px 12px 40px"
					background="--color-primary"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-primary"
					hover-color="--darkL1"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					hover-background="rgba(63, 36, 216, 0)"
				>
					Bize Ulaşın
				</Button>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://mobizaplay.com/img/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});